import type { ReferenceRendererProps } from '@contember/react-client'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import { InquiryForm } from '../InquiryForm'

export const inquiryForm = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section id={reference.type}>
			<InquiryForm
				title={reference.primaryText}
				stickyHeader={reference.viewStickyHeader ?? false}
			/>
		</section>
	)
}
