import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import styles from '../../app/styles/Steps.module.sass'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Link } from '../Link'

export const steps = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.wrapper, 'section')}>
			<Container>
				{reference.primaryText && <h2 className={styles.headline}>{reference.primaryText}</h2>}
				<div className={styles.inner}>
					{reference.stepList?.items.map((item, index) => (
						<div className={styles.item} key={item.id}>
							<div className={styles.number}>
								<span>{index + 1}</span>
							</div>
							<div className={styles.image}>
								{item.image && (
									<ContemberImage image={item.image} layout="fill" objectFit="cover" />
								)}
							</div>
							<article>
								<h3>{item.title}</h3>
								{item.desc}
								{item.link && <Link link={item.link} isBtn />}
							</article>
						</div>
					))}
				</div>
			</Container>
		</section>
	)
}
