import Head from 'next/head'
import React from 'react'
import type { LocalizedSeoFragmentType, SeoFragment } from '../app/data/content/SeoFragment'
import { unifySeo } from '../utils/unifySeo'

export interface SeoProps {
	seo?: LocalizedSeoFragmentType | SeoFragment
	title: string
}

export function Seo(props: SeoProps) {
	let seoTitle = `${props.title}`

	if (props.seo) {
		const seo = unifySeo(props.seo)
		seoTitle = seo.title ? `${seo.title} | Neon Hort` : 'Neon Hort'

		const ogImage = seo.ogImage || {
			url: '/fb.jpg',
			width: 1200,
			height: 630,
		}

		return (
			<Head>
				<title>{seoTitle}</title>
				<meta name="description" content={seo.description} />
				<meta property="og:title" content={props.seo ? seo.ogTitle : seoTitle} />
				<meta property="og:image" content={ogImage.url} />
				{ogImage.width && <meta property="og:image:width" content={`${ogImage.width}`} />}
				{ogImage.height && <meta property="og:image:height" content={`${ogImage.height}`} />}
				<meta property="og:description" content={seo.ogDescription || seo.description} />
			</Head>
		)
	} else {
		const ogImage = {
			url: '/fb.jpg',
			width: 1200,
			height: 630,
		}

		return (
			<Head>
				<title>{seoTitle}</title>
				<meta property="og:title" content={seoTitle} />
				<meta property="og:image" content={ogImage.url} />
				{ogImage.width && <meta property="og:image:width" content={`${ogImage.width}`} />}
				{ogImage.height && <meta property="og:image:height" content={`${ogImage.height}`} />}
			</Head>
		)
	}
}
