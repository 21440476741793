import { RichTextRenderer } from '@contember/react-client'
import type { ContentResult } from '../app/data/content/ContentFragment'
import * as referenceRenderers from './referenceRenderers'
import { Text } from './Text'

export interface ContentProps {
	content: ContentResult
	textual?: boolean
}

export const Content = (props: ContentProps) => {
	if (props.textual) {
		return (
			<Text>
				<RichTextRenderer
					blocks={props.content.blocks}
					sourceField="json"
					referenceRenderers={referenceRenderers}
				/>
			</Text>
		)
	}
	return (
		<RichTextRenderer
			blocks={props.content.blocks}
			sourceField="json"
			referenceRenderers={referenceRenderers}
		/>
	)
}
