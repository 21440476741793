import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import styles from '../../app/styles/Team.module.sass'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Insta } from '../Insta'

export const team = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.wrapper, 'section')}>
			<Container>
				{reference.primaryText && <h2 className={styles.headline}>{reference.primaryText}</h2>}
				<div className={styles.inner}>
					{reference.personList?.items.map((item) => (
						<div className={styles.item} key={item.id}>
							<div className={styles.image}>
								{item.image && (
									<ContemberImage image={item.image} layout="fill" objectFit="cover" />
								)}
							</div>
							<article className={styles.content}>
								<h3>
									{item.name} - {item.position}
								</h3>
								<p>{item.desc}</p>
								{item.instagram && (
									<a className={styles.insta} href={`https://www.instagram.com/${item.instagram}`}>
										<Insta />
										<span>@{item.instagram}</span>
									</a>
								)}
								{item.email && (
									<a className={styles.email} href={`mailto:${item.email}`}>
										<span>{item.email}</span>
									</a>
								)}
							</article>
						</div>
					))}
				</div>
			</Container>
		</section>
	)
}
