import clsx from 'clsx'
import React from 'react'
import styles from '../app/styles/Text.module.sass'

export function Text(props: { children: React.ReactNode; className?: string }) {
	return (
		<div className={clsx(styles.wrapper, props.className && styles[props.className])}>
			{props.children}
		</div>
	)
}
