import clsx from 'clsx'
import { useEffect, useState } from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import type { InspirationCategoryResult } from '../app/data/content/InspirationCategoryFragment'
import type { InspirationResult } from '../app/data/content/InspirationFragment'
import styles from '../app/styles/inspiration.module.sass'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
export function Inspiration(props: { headline: string }) {
	const [inspirationList, setInspirationList] = useState<InspirationResult[]>([])
	const [currentPage, setCurrentPage] = useState(1)
	const [thersMore, setThersMore] = useState(true)
	const [categories, setCategories] = useState<InspirationCategoryResult[]>([])
	const [activeFilters, setActiveFilters] = useState<string[]>([])

	const loadPosts = async () => {
		const response = await await fetch('/api/inspiration', {
			body: JSON.stringify({ page: currentPage, categories: categories }),
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
		})
		const results = await response.json()

		setInspirationList((oldInspirationList) => [...oldInspirationList, ...results.inspiration])

		if (results.totalCount.totalCount <= inspirationList.length) {
			setThersMore(false)
		}

		if (results.categories) {
			console.log(results.categories)
			setCategories(results.categories)
		}
	}

	useEffect(() => {
		loadPosts()
	}, [currentPage])

	const increment = () => {
		setCurrentPage(currentPage + 1)
		console.log(currentPage)
	}

	if (inspirationList === null) {
		return <div>Loading...</div>
	}

	return (
		<section className={clsx(styles.wrapper, 'section', 'inspiration')}>
			<Container>
				<div className={styles.inner}>
					<h2 className={styles.headline}>{props.headline}</h2>
					<div className={styles.filter}>
						<button
							className={clsx(styles.filterButton, activeFilters.length < 1 && styles.active)}
							onClick={(e) => {
								e.preventDefault()

								setActiveFilters([])
							}}>
							Vše
						</button>
						{categories.map((cat) => {
							return (
								<button
									key={cat.id}
									id={cat.id}
									className={clsx(
										styles.filterButton,
										activeFilters.includes(cat.id) && styles.active
									)}
									onClick={(e) => {
										e.preventDefault()
										if (activeFilters.includes(cat.id)) {
											setActiveFilters(activeFilters.filter((a) => a !== cat.id))
										} else {
											setActiveFilters((old) => [...old, cat.id])
										}
									}}>
									{cat.localesByLocale?.title}
								</button>
							)
						})}
					</div>
					<ResponsiveMasonry columnsCountBreakPoints={{ 750: 2, 900: 3, 1000: 4 }}>
						<Masonry>
							{inspirationList &&
								inspirationList.map((item) => {
									let inActiveFilters = false
									item.categories.forEach((cat) => {
										if (activeFilters.includes(cat.id)) {
											inActiveFilters = true
										}
									})
									if (activeFilters.length === 0 || inActiveFilters) {
										return (
											<div className={styles.single} key={item.id}>
												<div className={styles.overlay}>
													{item.logo && (
														<>
															<div className={styles.logo}>
																<ContemberImage image={item.logo} layout="responsive" />
															</div>
															{item.link && (
																<Link link={item.link} className="viewInspiration" isBtn />
															)}
														</>
													)}
												</div>
												{item.images?.items && item.images.items[0].image && (
													<ContemberImage
														sizes="(max-width: 900px) 50vw, (max-width: 1000px) 33vw, 25vw"
														image={item.images.items[0].image}
														layout="responsive"
													/>
												)}
											</div>
										)
									}
								})}
						</Masonry>
					</ResponsiveMasonry>
					{thersMore && (
						<Button
							onClick={() => {
								increment()
							}}>
							Načíst další
						</Button>
					)}
				</div>
			</Container>
		</section>
	)
}
