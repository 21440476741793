import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import styles from '../../app/styles/tiles.module.sass'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Link } from '../Link'

export const tiles = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section
			className={clsx(
				styles.wrapper,
				'section',
				reference.tileList?.items.length === 2 && styles.viewTwo
			)}>
			<Container>
				<div className={styles.inner}>
					{reference.tileList?.items.map((item) => (
						<div className={styles.single} key={item.id}>
							<div className={styles.image}>
								{item.image && (
									<ContemberImage image={item.image} objectFit="cover" layout="fill" />
								)}
							</div>
							{item.link && <Link nonExternal spanWrap link={item.link} />}
						</div>
					))}
				</div>
			</Container>
		</section>
	)
}
