import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import styles from '../../app/styles/header.module.sass'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Link } from '../Link'
import { Media } from '../Media'

export const header = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<header
			className={clsx(styles.wrapper, 'section', reference.headerView === 'side' && styles.side)}>
			<Container>
				<div className={styles.background}>
					<Media media={reference.media} cover />
				</div>
				<div className={styles.inner}>
					{reference.image && (
						<div className={styles.icon}>
							<ContemberImage image={reference.image} objectFit="cover" />
						</div>
					)}
					<h1 className={styles.headline}>{reference.primaryText}</h1>
					<div className={styles.links}>
						{reference.link && <Link nonExternal link={reference.link} isBtn />}
						{reference.secondaryLink && (
							<Link nonExternal link={reference.secondaryLink} isBtn className={'inverted'} />
						)}
					</div>
				</div>
			</Container>
		</header>
	)
}
