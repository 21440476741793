import type { LocalizedSeoFragmentType, SeoFragment } from '../app/data/content/SeoFragment'

export function unifySeo(seo: LocalizedSeoFragmentType | SeoFragment) {
	switch (seo.__typename) {
		case 'LocalizedSeo':
			return seo
		case 'Seo':
			return {
				title: seo.localesByLocale?.title,
				ogTitle: seo.localesByLocale?.ogTitle,
				description: seo.localesByLocale?.description,
				ogDescription: seo.localesByLocale?.ogDescription,
				ogImage: seo.ogImage,
			}
	}
}
