import type { LightboxSource } from '@mangoweb/fslightbox'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import type { AsyncReturnType } from 'type-fest'
import type { ImageResult } from '../app/data/content/ImageFragment'
import type { LinkResult } from '../app/data/content/LinkFragment'
import type { MediaResult } from '../app/data/content/MediaFragment'
import styles from '../app/styles/sideBySide.module.sass'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
import { Media } from './Media'

const loadLightbox = () => import('@mangoweb/fslightbox').then((module) => module.lightbox)

type Lightbox = AsyncReturnType<typeof loadLightbox>

export function SideBySide(props: {
	view?: string
	icon?: ImageResult
	image?: ImageResult
	media?: MediaResult
	headline?: string
	imageView?: string
	link?: LinkResult
	children: React.ReactChild
}) {
	const [lightbox, setLightbox] = React.useState<null | Lightbox>(null)

	React.useEffect(() => {
		loadLightbox().then((lightbox) => setLightbox(() => lightbox))
	}, [])

	const video = props.media ? props.media.url : null

	const gallery = useMemo(() => {
		if (lightbox && video) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			const sources: LightboxSource[] = [
				{
					source: String(video),
					type: 'video',
					caption: '',
					isVideo: true,
				},
			]

			return lightbox(sources)
		}
	}, [props.image, props.media, lightbox])

	return (
		<section className={clsx(styles.wrapper, 'section')}>
			<Container>
				<div className={clsx(styles.inner, props.view && styles[props.view])}>
					<div className={styles.content}>
						<div className={styles.icon}>{props.icon && <ContemberImage image={props.icon} />}</div>
						<h2 className={styles.headline}>{props.headline}</h2>
						<article className={styles.description}>{props.children}</article>
						{props.link && <Link isBtn link={props.link} nonExternal />}
					</div>
					<div className={clsx(styles.image, props.imageView && styles[props.imageView])}>
						{props.image && <ContemberImage image={props.image} objectFit="cover" layout="fill" />}
						{props.media && !props.image && <Media media={props.media} />}
						{props.media && props.image && (
							<div className={styles.videoMiniture}>
								<div className={styles.videoThumbnail}>
									{props.media && props.image && (
										<Media sizes="(max-width: 736px) 90vw, 40vw" media={props.media} />
									)}
								</div>
								<Button
									onClick={() => {
										gallery?.open(0)
									}}
									className="viewLight">
									Podívejte se na video
								</Button>
							</div>
						)}
					</div>
				</div>
			</Container>
		</section>
	)
}
