/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import type { ContentResult } from '../app/data/content/ContentFragment'
import { ContemberImage } from './ContemberImage'

type MediaProps = {
	media: ContentResult['blocks'][number]['references'][number]['media'] | null
	cover?: boolean
	contain?: boolean
	priority?: boolean
	sizes?: string
}

export function Media(props: MediaProps) {
	switch (props.media?.type) {
		case 'image':
			return (
				<ContemberImage
					image={{
						id: props.media.id,
						url: props.media.poster ?? '',
						height: props.media.height,
						width: props.media.width,
					}}
					objectFit={props.cover ? 'cover' : props.contain ? 'contain' : undefined}
					layout={props.cover ? 'fill' : undefined}
					sizes={props.sizes}
					priority={props.priority}
				/>
			)
		case 'video':
			return (
				<video muted autoPlay loop playsInline>
					<source src={props.media.url} type="video/mp4" />
				</video>
			)
	}

	return null
}
