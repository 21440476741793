import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import styles from '../../app/styles/tileHeader.module.sass'
import { ArrowNew } from '../ArrowNew.svg'
import { Container } from '../Container'
import { Link } from '../Link'
import { Media } from '../Media'

export const tileHeader = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<header className={clsx(styles.wrapper, 'section')}>
			<Container>
				<div className={styles.inner}>
					<div className={styles.tiles}>
						{reference.tileList?.items.map((item) => (
							<div key={item.id} className={styles.item}>
								{item.media && (
									<Media priority media={item.media} cover sizes="(max-width: 736px) 100vw, 50vw" />
								)}
								<h1>
									{item.link && <Link nonExternal link={item.link} />}
									<button className={styles.button}>
										<ArrowNew />
									</button>
								</h1>
							</div>
						))}
					</div>
				</div>
			</Container>
		</header>
	)
}
