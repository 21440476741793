import type { ReferenceRendererProps } from '@contember/react-client'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import { SideBySide } from '../SideBySide'

export const sideBySide = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<SideBySide
			headline={reference.primaryText}
			icon={reference.icon}
			image={reference.image}
			media={reference.media}
			imageView={reference.imageView}
			link={reference.link}
			view={reference.sideBySideView}>
			{children}
		</SideBySide>
	)
}
