import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import styles from '../../app/styles/features.module.sass'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Link } from '../Link'

export const features = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.wrapper, 'section')}>
			<Container>
				<ul className={styles.list}>
					{reference.featureList?.items.map((item) => (
						<li key={item.id} className={styles.single}>
							<div className={styles.icon}>{item.icon && <ContemberImage image={item.icon} />}</div>
							<h3>{item.title}</h3>
							{item.link && <Link link={item.link} />}
						</li>
					))}
				</ul>
				{reference.link && <Link link={reference.link} isBtn />}
			</Container>
		</section>
	)
}
