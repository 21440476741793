import clsx from 'clsx'
import type { ProductResult } from '../app/data/content/ProductFragment'
import styles from '../app/styles/ProductList.module.sass'
import { LinkType } from '../generated/content'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'

export function ProductList(props: { products: Array<ProductResult>; title?: string }) {
	return (
		<section className={clsx(styles.wrapper, 'section')}>
			<Container>
				{props.title && <h2 className={styles.headline}>{props.title}</h2>}
				<div className={styles.inner}>
					{props.products.map((item) => (
						<div className={styles.single} key={item.id}>
							<div className={styles.image}>
								{item.images?.items[0].image && (
									<ContemberImage
										image={item.images?.items[0].image}
										objectFit="cover"
										layout="fill"
									/>
								)}
							</div>
							<div className={styles.content}>
								<h3>
									{item.localesByLocale?.name}
									<br />
									<span className={styles.price}>{item.localesByLocale?.price} Kč</span>
								</h3>
								{item.localesByLocale?.link?.url && (
									<Link
										link={{
											type: LinkType.internal,
											internalLink: { url: item.localesByLocale?.link?.url },
											title: 'Koupit',
										}}
										isBtn
									/>
								)}
							</div>
						</div>
					))}
				</div>
			</Container>
		</section>
	)
}
