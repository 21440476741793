import type { ReferenceRendererProps } from '@contember/react-client'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import { Gallery } from '../Gallery'

export const gallery = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<Gallery
			images={reference.gallery}
			title={reference.primaryText ? reference.primaryText : undefined}
		/>
	)
}
