import React from 'react'

export function ArrowNew() {
	return (
		<svg width="38" height="31" viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21.2034 1.55829L34.8545 15.2093M34.8545 15.2093L21.2034 28.8604M34.8545 15.2093H0.726866"
				stroke="#07160D"
				strokeWidth="4"
			/>
		</svg>
	)
}
