import type { LightboxSource } from '@mangoweb/fslightbox'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import type { AsyncReturnType } from 'type-fest'
import type { GalleryItems } from '../app/data/content/ContentFragment'
import styles from '../app/styles/Gallery.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'

const loadLightbox = () => import('@mangoweb/fslightbox').then((module) => module.lightbox)

type Lightbox = AsyncReturnType<typeof loadLightbox>

export function Gallery(props: { images: GalleryItems; title?: string }) {
	const [lightbox, setLightbox] = React.useState<null | Lightbox>(null)

	React.useEffect(() => {
		loadLightbox().then((lightbox) => setLightbox(() => lightbox))
	}, [])

	const gallery = useMemo(() => {
		if (lightbox && props.images) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			const sources: LightboxSource[] = props.images.items.map((item) => ({
				source: String(item.image?.url),
				type: 'image',
				caption: '',
			}))

			return lightbox(sources)
		}
	}, [props.images, lightbox])

	return (
		<section className={clsx(styles.wrapper, 'section')}>
			<Container>
				{props.title && <h2 className={styles.headline}>{props.title}</h2>}
				<div className={styles.inner}>
					{props.images?.items.map((item, index) => (
						<button
							className={styles.single}
							key={item.id}
							onClick={() => {
								gallery?.open(index)
							}}>
							{item.image && <ContemberImage image={item.image} layout="fill" objectFit="cover" />}
						</button>
					))}
				</div>
			</Container>
		</section>
	)
}
