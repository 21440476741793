/* eslint-disable @typescript-eslint/ban-ts-comment */
import clsx from 'clsx'
import React, { ChangeEvent, ChangeEventHandler, RefObject } from 'react'
import styles from '../app/styles/InquiryForm.module.sass'
import { Button } from './Button'
import { Container } from './Container'
import { Crosss } from './Cross.svg'

export function InquiryForm(props: { title?: string; stickyHeader: boolean }) {
	const [file, setFileState] = React.useState<File | null>(null)
	const handleFile: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent) => {
		const fileInput = event.target as HTMLInputElement

		if (fileInput.files) {
			setFileState(fileInput.files[0])
		}
	}

	const submitForm = async () => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const form = ref.current!

		const policy = true //dodelat checkbox

		const data = new FormData(form)

		if (policy && file) {
			const payload = {
				name: data.get('name'),
				surname: data.get('surname'),
				email: data.get('email'),
				fileType: file ? file.type : null,
				fileSize: file ? file.size : null,
				interest: data.get('interest'),
				description: data.get('description'),
				phone: data.get('phone'),
			}

			setFormState('sending')

			const res = await fetch('/api/form', {
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
			})

			const result = await res.json()

			if (result.status === 'ok') {
				if (result.file.generateUploadUrl) {
					const u = result.file.generateUploadUrl
					const upload = await fetch(u.url, {
						method: u.method,
						body: file,
						//@ts-ignore
						headers: u.headers.reduce((headers, h) => ({ ...headers, [h.key]: h.value }), {}),
					})

					console.log({
						file,
						publicUrl: u.publicUrl,
						uploadResult: upload,
					})
				}
				setFormState('success')
			} else {
				setFormState('failed')
			}
		} else {
			setFormState('policy-file-failed')
		}
	}

	const [formState, setFormState] = React.useState<null | string>('initial')

	const ref = React.useRef() as RefObject<HTMLFormElement>

	return (
		<div
			className={clsx(styles.wrapper, 'section', props.stickyHeader && styles.viewStickyHeader)}
			id="inquiry">
			<Container>
				{props.title && <h2 className={styles.headline}>{props.title}</h2>}
				<div className={styles.inner}>
					{formState !== 'success' && (
						<form
							ref={ref}
							className={clsx(styles.form, formState === 'sending' && styles.sending)}>
							<input type="text" name="name" placeholder="Jméno" required></input>
							<input type="text" name="surname" placeholder="Přijmení" required></input>
							<input type="text" name="phone" placeholder="Telefon" required></input>
							<input type="email" name="email" placeholder="E-mail" required></input>
							<div className={styles.textareaWrapper}>
								<textarea
									className={styles.textarea}
									onInput={(e) => {
										e.currentTarget.style.minHeight = '0'
										e.currentTarget.style.minHeight = `${e.currentTarget.scrollHeight}px`
									}}
									placeholder="Popište vaši představu o neonu"
									name="description"></textarea>
							</div>
							<label htmlFor="file">
								{file ? file.name : 'Přiložte soubor s logem nebo obrázek vaší představy'}{' '}
								<span>
									<Crosss />
								</span>
							</label>
							<input
								onChange={handleFile}
								className={styles.file}
								id="file"
								type="file"
								name="file"></input>
							<Button
								onClick={() => {
									submitForm()
								}}
								className="viewTransaction">
								ODESLAT
							</Button>
						</form>
					)}
					{formState === 'success' && (
						<p className={styles.success}>Děkujeme za zprávu, brzy se vám ozveme.</p>
					)}
					{formState === 'policy-file-failed' && (
						<p className={styles.fileMissing}>
							Vložte prosím soubor s vaším logem nebo představou.
						</p>
					)}
				</div>
			</Container>
		</div>
	)
}
