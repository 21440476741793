import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { ContentBlockReference } from '../../app/data/content/ContentFragment'
import styles from '../../app/styles/image.module.sass'
import { ContemberImage } from '../ContemberImage'

export const image = ({ reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<div className={clsx(styles.wrapper)}>
			{reference.image && <ContemberImage image={reference.image} layout="responsive" />}
		</div>
	)
}
